import React, { useState } from "react";

import useGetAwsPersonalizeProducts from "@/hooks/useGetAwsPersonalizeProducts";
import {
  BEST_SELLER,
  MOST_VIEWED,
  MY_RECOMMENDATIONS,
} from "@/services/global/aws-personalize";
import { useRouter } from "next/router";
import ArrowIcon from "../icons/ArrowIcon";
import Container from "./Container";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../Helpers/ProductCard";
import { hotProductsBreakPoints } from "@/constants/BreakPoints";
import { useTranslation } from "react-i18next";
import ProductCardSkeleton from "../Helpers/ProductCardSkeleton";

const HomeAWSProductsSwiper = ({ title, type, backgroundColor = "#fff" }) => {
  const [swiper, set_swiper] = useState({});
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { locale } = useRouter();
  const { t } = useTranslation("common");

  const awsKey =
    type === "recommended"
      ? MY_RECOMMENDATIONS
      : type === "bestselling"
      ? BEST_SELLER
      : type === "mostviewed"
      ? MOST_VIEWED
      : null;

  const { products, isLoading } = useGetAwsPersonalizeProducts(awsKey);

  return (
    <>
      {products?.length > 0 && (
        <Container className="my-10">
          <div className="flex flex-col justify-between gap-4">
            <h2
              className={`max-[570px]:text-2xl text-3xl font-semibold cursor-pointer ${
                process.env.NEXT_PUBLIC_WEBSITE_MODE == "extreme"
                  ? "text-white"
                  : "text-blue"
              } px-3`}
            >
              {title}
            </h2>
            <div
              className={`py-8 ${
                locale == "ar" ? "pr-16" : "pl-16"
              } p-16 rounded-lg  max-[570px]:p-3`}
              style={{ backgroundColor }}
            >
              <div className="col-span-8 my-auto max-[570px]:col-span-full">
                <Swiper
                  className="w-full"
                  spaceBetween={12}
                  onSlideChange={(swiper) => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                  }}
                  onInit={(swiper) => {
                    set_swiper(swiper);
                  }}
                  dir={locale === "ar" ? "rtl" : "ltr"}
                  key={locale}
                  breakpoints={hotProductsBreakPoints(true)}
                >
                  {isLoading &&
                    [1, 2, 3, 4, 5].map((i) => (
                      <SwiperSlide key={i * 3454}>
                        <ProductCardSkeleton />
                      </SwiperSlide>
                    ))}
                  {products?.length
                    ? products?.map((product) => (
                        <SwiperSlide key={product?.sku}>
                          <ProductCard
                            {...{
                              t,
                              product: product,
                              customHeight: "h-[30rem]",
                            }}
                          />
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>
                <div className="min-[1700px]:max-w-[1500px] m-auto relative hidden min-[992px]:block">
                  {(locale === "ar" ? !isEnd : !isBeginning) && (
                    <button
                      onClick={() =>
                        locale === "ar"
                          ? swiper?.slideNext()
                          : swiper?.slidePrev()
                      }
                      className="absolute z-[99] items-center justify-center bg-white w-7 h-7 flex -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -left-12 size-10 max-[991px]:flex"
                    >
                      <ArrowIcon className="text-black rotate-180 size-4" />
                    </button>
                  )}
                  {(locale === "ar" ? !isBeginning : !isEnd) && (
                    <button
                      onClick={() =>
                        locale === "ar"
                          ? swiper?.slidePrev()
                          : swiper?.slideNext()
                      }
                      className="absolute z-[99] items-center bg-white w-7 h-7 flex justify-center -translate-y-1/2 rounded-full text-tertiary-600 -top-[200px] -right-12 size-10 max-[991px]:flex"
                    >
                      <ArrowIcon className="text-black size-4" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default HomeAWSProductsSwiper;
